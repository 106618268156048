html,
body {
    font-family: "Arial", sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    word-break: break-word;
    //prevent flashing on load
    background-color: $menu-bg-color;
}


//prevent the colored highlighting of links on certain elements (for menu items) on chrome
.webkitNoSelect {
    -webkit-tap-highlight-color: transparent; 
} 

// Webkit scrollbar
/* width */
::-webkit-scrollbar {
    width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: darken(#888, 10%);
}
